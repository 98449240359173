(function () {
	'use strict';

	angular
		.module('header')
		.directive('sliderWidth', sliderWidth)
		.directive('headerControls', ['environment', headerControls])
		.directive('headerMenuView', ['environment', headerMenuView])
		.directive('headerMenuHome', ['environment', headerMenuHome])
		.directive('headerMenuHorizontal', [
			'environment',
			headerMenuHorizontal,
		])
		.directive('headerMenuVertical', ['environment', headerMenuVertical])
		.directive('headerMenuDay', ['environment', headerMenuDay])
		.directive('headerMenuSharing', ['environment', headerMenuSharing])
		.directive('headerMenuShareMore', ['environment', headerMenuShareMore])
		.directive('headerMenuNotifications', [headerMenuNotifications])
		.directive('menuNotifications', ['$rootScope', menuNotifications])
		.directive('dateHeaderResize', [dateHeaderResize]);

	function sliderWidth() {
		return {
			link: function (scope, element, attrs) {
				var navElement = $('.view-nav');
				var navWidthWatcher = scope.$watch(
					function () {
						return navElement.width();
					},
					function (newValue, oldValue) {
						element.width(newValue - 28);
						// navWidthWatcher(); // Currently disabled as the vertical menu isn't fully sized right away
					}
				);
			},
		};
	}

	function headerControls(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/controls.html',
		};
	}

	function headerMenuView(environment) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				var headerElement = document.querySelector('#header');

				// Menu definition and properties here. For example height and width for each view
				var defaultMenuStyle = {height: '160px'};
				var views = {
					day: {
						display: 'Day',
						styles: {
							height: defaultMenuStyle.height,
						},
					},
					week: {
						display: 'Week',
						styles: {
							height: defaultMenuStyle.height,
						},
					},
					month: {
						display: 'Month',
						styles: {
							height: defaultMenuStyle.height,
						},
					},
					horizon: {
						display: 'Horizon',
						styles: {
							height: '500px',
						},
					},
					resource: {
						display: 'Resource',
						styles: {
							height: '340px',
						},
					},
				};

				// Initialize vertical menu styling
				scope.menuHeight = defaultMenuStyle;

				setMenuFromWidth();
				setHeaderMenu();

				scope.$on('resize', function () {
					setMenuFromWidth();
				});

				scope.changeHeaderPanel = function ($event, name, change) {
					$event.stopPropagation();
					setHeaderMenu(name, change);
				};

				function setHeaderMenu(name, change) {
					if (!scope.header.panel) {
						scope.header.panel = {};
					}
					scope.header.panel.show = change ? name : null;

					if (change) {
						scope.header.panel[name] = change;
					}
					scope.selectedView = change
						? views[change].display
						: 'View';
					scope.menuHeight =
						change && views[change]
							? views[change].styles
							: defaultMenuStyle;
				}

				function setMenuFromWidth() {
					if (headerElement && headerElement.offsetWidth < 1000) {
						scope.verticalMenu = true;
					} else {
						scope.verticalMenu = false;
					}
				}
			},
			template:
				'<div class="nav nav-pills nav-group"><header-menu-horizontal ng-if="!verticalMenu"></header-menu-horizontal>' +
				'<header-menu-vertical ng-if="verticalMenu"></header-menu-vertical></div>',
		};
	}

	function headerMenuHome(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/home.html',
		};
	}

	function headerMenuHorizontal(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/horizontal.html',
		};
	}

	function headerMenuVertical(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/vertical.html',
		};
	}

	function headerMenuDay(environment) {
		return {
			restrict: 'EA',
			templateUrl: function (tElement, tAttributes) {
				var templateRoot = tAttributes.templateRoot;
				var template = hiddenMenuToTemplate(templateRoot);
				return 'app/header/menu/views/' + template + '.html';
			},
		};
	}

	function headerMenuSharing(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/sharing.html',
		};
	}

	function headerMenuShareMore(environment) {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/share-more.html',
		};
	}

	function headerMenuNotifications() {
		return {
			restrict: 'EA',
			templateUrl: 'app/header/menu/notifications.html',
		};
	}

	function menuNotifications($rootScope) {
		const registeredMethods = new Map();
		return {
			restrict: 'EA',
			scope: {
				notifications: '=',
				purchase: '=',
			},
			controller: function ($scope) {
				$scope.$watch(
					() => {
						return JSON.stringify($scope.notifications);
					},
					(newValue, oldValue) => {
						const updateNotificationsCallback =
							registeredMethods.get(
								'updateNotificationsCallback'
							);
						// Run update procedure
						updateNotificationsCallback(
							createNotificationCallbackData($scope.notifications)
						);
					}
				);
				$scope.props = {
					registerMethod: registerMethod,
					purchase: $scope.purchase.gotoPurchase,
				};
				function registerMethod(method, func) {
					// Registered methods are:
					// shownCallback
					// updateNotificationsCallback
					registeredMethods.set(method, func);
				}
			},
			link: function (scope, element, attrs) {
				const shownCallback = registeredMethods.get('shownCallback');
				const updateNotificationsCallback = registeredMethods.get(
					'updateNotificationsCallback'
				);
				$rootScope.$on('menu-notifications-shown', ($event, shown) => {
					shownCallback(shown);
					if (shown) {
						updateNotificationsCallback(
							createNotificationCallbackData(scope.notifications)
						);
					}
				});
			},
			template:
				'<svelte-component component="notifications" props=props></svelte-component>',
		};

		function createNotificationCallbackData(notificationData) {
			return {
				showTrialExpires:
					notificationData?.notifications?.license?.show &&
					notificationData?.notifications?.license
						?.notificationType === 'trial' &&
					!notificationData?.notifications?.useStaticData,
				trialExpiresAlert:
					notificationData?.notifications?.license?.expiresAlert,
				trialExpiresDayString:
					notificationData?.notifications?.license?.dayString,
				useStaticData: notificationData?.notifications?.useStaticData,
			};
		}
	}

	function dateHeaderResize() {
		return {
			restrict: 'EA',
			scope: {},
			link: function (scope, element, attrs) {
				var compareElement = $('.view-nav');

				//Watch for resize broadcast (window resize or sidebar open/close)
				scope.$on('resize', resizeText);

				//Clean up external events on element destroy
				// scope.$on('$destroy', function () {
				// // No need to cleen anything up here as it's currently all attached to the scope
				// });

				var waitingToResize;
				var resizing;
				var force;

				scope.$watch(
					function () {
						return element.width();
					},
					function (newValue, oldValue) {
						resizeText();
					}
				);
				//Resize our text
				function resizeText(e, type) {
					// Clear any pending timeouts because a resize was triggered again
					clearTimeout(waitingToResize);

					force = type === 'sidebar';
					//Don't procceed if the elments have no width. This most likely means they aren't displayed.
					if (!element.width() || !compareElement.width()) {
						return;
					}

					applyResize();
				}

				function applyResize() {
					waitingToResize = window.setTimeout(function () {
						if (!resizing || force) {
							//Reset the element font size to original
							element.css('font-size', '');
						}
						resizing = true;
						var loopCount = 0;
						while (
							element.position().left + element.width() >=
								compareElement.position().left &&
							loopCount < 4
						) {
							loopCount++;
							element.css(
								'font-size',
								parseInt(element.css('font-size')) - 5 + 'px'
							);
						}
						resizing = null;
					}, 0);
				}
			},
		};
	}

	function matchArrayItem(value, array) {
		if (!array) {
			return;
		}
		for (var i = 0; i < array.length; i++) {
			if (value === array[i]) {
				return true;
			}
		}
	}

	function hiddenMenuToTemplate(menuRoot) {
		var subMenus = ['', 'list', 'schedule', 'grid'];
		var config = seedcodeCalendar.get('config');
		var hideItems = config.hideMenuItems;
		var hiddenStatus = [];
		var hasHidden;

		for (var i = 0; i < subMenus.length; i++) {
			hiddenStatus[i] = matchArrayItem(menuName, hideItems);
			if (hiddenStatus[i]) {
				hasHidden = true;
			}
		}

		if (!hasHidden) {
			return menuRoot;
		} else {
			return 'another template';
		}
	}
})();
